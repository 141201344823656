export default {
  path: '/',
  component: () => import('@/layouts/LayoutContentHorizontalNav.vue'),
  meta: { protected: true },
  children: [
    {
      path: '/admission/get-admissions',
      name: 'getAdmissions',
      component: () => import('@/views/admission/AdmissionTable.vue'),
    },
  ],
}
