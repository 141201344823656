const API_BASE_AUTH = process.env.VUE_APP_API_BASE_AUTH
const API_BASE_V1 = process.env.VUE_APP_API_BASE_V1

console.log('mode:', process.env.NODE_ENV)

export default {
  // base api's
  INSTITUTION: `${API_BASE_AUTH}/organisation/institution`,
  ADMIN_DASHBOARD: `${API_BASE_V1}/admin/dashboard`,
  TRUST: `${API_BASE_V1}/fs/trust`,
  BATCH: `${API_BASE_V1}/batch`,
  CLASS: `${API_BASE_V1}/class`,
  ANNOUNCEMENT: `${API_BASE_V1}/announcement`,
  ANNOUNCEMENT_STUDENT: `${API_BASE_V1}/announcement/student`,
  ANNOUNCEMENT_STAFF: `${API_BASE_V1}/announcement/staff`,

  // roles & permissions
  ROLES: `${API_BASE_AUTH}/roles/`,
  MODULES: `${API_BASE_AUTH}/modules/`,
  PERMISSIONS: `${API_BASE_AUTH}/permission/`,

  // documents api
  IMAGE: `${API_BASE_V1}/image`,
  DOCUMENTS: `${API_BASE_V1}/documents`,

  // subject api's
  SUBJECT: `${API_BASE_V1}/subject`,
  SUBJECT_BATCH: `${API_BASE_V1}/subject/batch`,
  CLASS_SUBJECT: `${API_BASE_V1}/class/subject`,
  SUBJECT_LIST: `${API_BASE_V1}/subject/list`,
  SUBJECT_CO_SCHOLASTIC_LIST: `${API_BASE_V1}/subject/co-scholastic/list`,
  CLASS_SUBJECT_TEACHER: `${API_BASE_V1}/class/subject/teacher`,

  // student api's
  STUDENT: `${API_BASE_V1}/student`,
  STUDENT_ADMISSION: `${API_BASE_V1}/student/admission`,
  STUDENT_BATCH: `${API_BASE_V1}/student/batch`,
  STUDENT_BATCH_DEACTIVE: `${API_BASE_V1}/student/batch/de-active`,
  STUDENT_BATCH_FACILITY: `${API_BASE_V1}/student/batch/facility`,
  STUDENT_BATCH_PROFILE: `${API_BASE_V1}/student/batch/profile`,
  STUDENT_BATCH_ROLL_PROFILE: `${API_BASE_V1}/student/batch/roll/profile`,
  STUDENT_CLASS_COUNT: `${API_BASE_V1}/student/class-count`,
  STUDENT_COMMENTS: `${API_BASE_V1}/student/comments`,
  STUDENT_DETAILS: `${API_BASE_V1}/student/details`,
  STUDENT_SEARCH: `${API_BASE_V1}/student/search`,
  STUDENT_SEARCH_INFO: `${API_BASE_V1}/student/search/info`,
  ACTIVATE_STUDENT: `${API_BASE_V1}/activate/student`,
  STUDENT_ROLLNO_ADMNO: `${API_BASE_V1}/student/roll/admission`,

  // account api's
  STUDENT_PAYMENT_RECORD: `${API_BASE_V1}/fees/record/student`,
  STUDENT_PAYMENT_RECEIPT: `${API_BASE_V1}/fees/record/student/receipt`,
  FIND_STUDENT_PAYMENT_RECEIPT: `${API_BASE_V1}/fees/record/student/receipt/find`,
  COLLECT_PAYMENT: `${API_BASE_V1}/fees/collection/payment`,
  FEES_COLLECTION_STUDENT: `${API_BASE_V1}/fees/collection/student`,
  FEES: `${API_BASE_V1}/fs/fees`,
  FEES_CLASS: `${API_BASE_V1}/fs/fees/class`,
  FEES_CLASS_LIST: `${API_BASE_V1}/fs/fees/class/list`,
  FEES_STUDENT: `${API_BASE_V1}/fs/fees/student`,
  FEES_STUDENT_LIST: `${API_BASE_V1}/fs/fees/student/list`,
  FEES_FINE: `${API_BASE_V1}/fs/fees/fine
  `,
  STUDENT_PAY_LIST: `${API_BASE_V1}/fees/master/student/record`,
  STUDENT_PAID_FEE: `${API_BASE_V1}/fees/master/student/fees`,

  // discount api's
  DISCOUNT_GROUP: `${API_BASE_V1}/ds/discount-group`,
  DISCOUNT_GROUP_STUDENT: `${API_BASE_V1}/ds/discount-group/student`,
  DISCOUNT_GROUP_STUDENTS: `${API_BASE_V1}/ds/discount-group/students`,
  DISCOUNT_GROUP_FEES: `${API_BASE_V1}/ds/discount-group/fees`,
  DISCOUNT_GROUP_FEES_LIST: `${API_BASE_V1}/ds/discount-group/fees/list`,
  DISCOUNT_SEARCH_STUDENTS: `${API_BASE_V1}/ds/search/students`,
  INDIVIDUAL_DISCOUNT_LIST: `${API_BASE_V1}/ds/individual-discount/list`,
  INDIVIDUAL_DISCOUNT: `${API_BASE_V1}/ds/individual-discount`,

  // exam setting
  EXAM_CATEGORY: `${API_BASE_V1}/exams/es/category`,
  EXAM_TERM_ALL: `${API_BASE_V1}/exams/es/term/all`,
  EXAM_TERM: `${API_BASE_V1}/exams/es/term`,
  EXAM_ALL: `${API_BASE_V1}/exams/es/exam/all`,
  EXAM: `${API_BASE_V1}/exams/es/exam`,
  EXAM_ACTIVITY_ALL: `${API_BASE_V1}/exams/es/exam/activity/all`,
  EXAM_ACTIVITY: `${API_BASE_V1}/exams/es/exam/activity`,
  EXAM_COSCHOLASTIC_GRADE_ALL: `${API_BASE_V1}/exams/es/coscholastic/grade/all`,
  EXAM_COSCHOLASTIC_GRADE: `${API_BASE_V1}/exams/es/coscholastic/grade`,
  EXAM_SCHOLASTIC_GRADE_ALL: `${API_BASE_V1}/exams/es/scholastic/grade/all`,
  EXAM_SCHOLASTIC_GRADE: `${API_BASE_V1}/exams/es/scholastic/grade`,

  // marks entry
  GET_SUBJECT_MARK: `${API_BASE_V1}/exams/me/student/subject/marks/all`,
  SUBJECT_MARK: `${API_BASE_V1}/exams/me/student/subject/marks`,
  STUDENT_SUBJECT_MARKS: `${API_BASE_V1}/exams/me/student/subject/marks/students`,
  STUDENT_CO_SCHOLASTIC_MARKS: `${API_BASE_V1}/exams/me/student/co-scholastic/marks/students`,

  // EXAM_COSCHOLASTIC_REMARKS_ALL: `${API_BASE_V1}/exams/me/student/co-scholastic/remarks/all`,
  // EXAM_COSCHOLASTIC_REMARKS: `${API_BASE_V1}/exams/me/student/co-scholastic/remarks`,
  // EXAM_ATTENDANCE_REMARKS_ALL: `${API_BASE_V1}/exams/me/student/attendance/remarks/all`,
  // EXAM_ATTENDANCE_REMARKS: `${API_BASE_V1}/exams/me/student/attendance/remarks`,

  EXAM_STUDENT_COSCHOLASTIC_REMARKS: `${API_BASE_V1}/exams/me/student/co-scholastic/remarks/students`,
  EXAM_STUDENT_ATTENDANCE_REMARKS: `${API_BASE_V1}/exams/me/student/attendance/remarks/students`,

  // Skills api's
  EXAM_SKILLS_ALL: `${API_BASE_V1}/exams/es/skills/all`,
  EXAM_SKILLS: `${API_BASE_V1}/exams/es/skills`,
  EXAM_SUB_SKILLS_ALL: `${API_BASE_V1}/exams/es/sub-skills/all`,
  EXAM_SUB_SKILLS: `${API_BASE_V1}/exams/es/sub-skills`,
  EXAM_SUB_SKILLS_BY_PARENT: `${API_BASE_V1}/exams/es/sub-skills/skills`,
  EXAM_COSCHOLASTIC_MARKS_ALL: `${API_BASE_V1}/exams/me/student/co-scholastic/marks/all`,
  EXAM_COSCHOLASTIC_MARKS: `${API_BASE_V1}/exams/me/student/co-scholastic/marks`,
  GET_CHILDSKIL: `${API_BASE_V1}/exams/es/sub-skills/all`,
  GET_DESCRIPTIVEINDI: `${API_BASE_V1}/exams/es/descriptive/indicator/all`,
  CREATE_DESCRIPTIVEINDI: `${API_BASE_V1}/exams/es/descriptive/indicator`,

  // exam report api's
  STUDENT_REPORT_CARD: `${API_BASE_V1}/exams/me/student/report/card`,
  STUDENTS_REPORT_CARDS: `${API_BASE_V1}/exams/me/student/report/card/class/section`,
  STUDENTS_REPORT_CARDS_AFTER_FREEZE: `${API_BASE_V1}/exams/me/student/report/card/class/section/af`,
  STUDENT_REPORT_CARD_MARKS: `${API_BASE_V1}/exams/me/student/report/card/marks`,
  FREEZE_MARKS: `${API_BASE_V1}/exams/me/student/report/card/class/section/freeze/marks`,
  FREEZE_MARKS_CHECK: `${API_BASE_V1}/exams/me/student/report/card/class/section/freeze/marks/check`,
  STUDENT_REPORT_CARD_SESSION: `${API_BASE_V1}/exams/me/student/report/card/session`,
  STUDENTS_REPORT_CARDS_AFTER_FREEZE_SESSION: `${API_BASE_V1}/exams/me/student/report/card/class/section/af/session`,

  // staff api's
  STAFF: `${API_BASE_V1}/staff`,
  STAFF_BY_INSTITUTION: `${API_BASE_V1}/staff/institution`,
  INACTIVE_STAFF: `${API_BASE_V1}/staff/institution/inactive`,
  STAFF_ROLE: `${API_BASE_V1}/staff/role`,
  STAFF_FAMILY_MEMBERS: `${API_BASE_V1}/staff/members`,
  ACTIVATE_STAFF: `${API_BASE_V1}/staff/activate`,
  STAFF_SCHEDULE: `${API_BASE_V1}/staff/subject`,

  // attendance
  ATTENDANCE: `${API_BASE_V1}/attendance`,
  GET_ATTENDANCE_REPORT: `${API_BASE_V1}/attendance/class`,
  GET_ATTENDANCE: `${API_BASE_V1}/attendance/get-attendance`,
  TEMPLATE_MESSAGE: `${API_BASE_V1}/message/template`,

  // homework
  HOMEWORK: `${API_BASE_V1}/homework`,
  HOMEWORK_BY_DATE: `${API_BASE_V1}/homework/day`,
  HOMEWORK_PUBLISH: `${API_BASE_V1}/homework/publish`,

  // admin

  GET_STAFF: `${API_BASE_V1}/admin/staff/profile`,

  // teacher controller

  ADD_CONTENT: `${API_BASE_V1}/teacher/content`,

  // enquiry api's
  ENQUIRY: `${API_BASE_V1}/enquiry`,
  ENQUIRY_SEARCH: `${API_BASE_V1}/enquiry/search`,
  ENQUIRY_STATUS: `${API_BASE_V1}/enquiry/status`,
  ENQUIRY_DELETE: `${API_BASE_V1}/enquiry/delete`,
  ENQUIRY_FOLLOWUP: `${API_BASE_V1}/enquiry/followup`,
  SCHOOL_ENQUIRY: `${API_BASE_V1}/scan/school/enquiry`,

  // registration/Admission
  CREATE_REGISTRATION: `${API_BASE_V1}/orders/registrations`,
  OPEN_REGISTRATION: `${API_BASE_V1}/open/registration`,
  CHECK_STATUS: `${API_BASE_V1}/open/check-status`,
  REGISTRATION_DETAIL: `${API_BASE_V1}/open/registration/detail`,
  REGISTRATION_NUMBER: `${API_BASE_V1}/open/registration/number`,
  CREATE_ADMISSION: `${API_BASE_V1}/open/admission`,
  GET_ADMISSION_DETAILS: `${API_BASE_V1}/open/admission/detail`,
  GET_ADMISSIONS: `${API_BASE_V1}/orders/online/admissions`,

  // transport api's
  TRANSPORT_DRIVER: `${API_BASE_V1}/transport/driver`,
  TRANSPORT_DRIVERS: `${API_BASE_V1}/transport/drivers`,
  ONLINE_CLASS: `${API_BASE_V1}/online/class`,

}
