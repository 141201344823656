import accountRoutes from './accountRoutes'
import attendanceRoutes from './attendanceRoutes'
import collectFeeRoutes from './collectFeeRoutes'
import enquiryRoutes from './enquiryRoutes'
import examinationRoutes from './examinationRoutes'
import reportRoutes from './reportRoutes'
import staffRoutes from './staffRoutes'
import studentRoutes from './studentRoutes'
import SubjectRoutes from './SubjectRoutes'
import transportRoutes from './transportRoutes'
import econtentRoutes from './econtentRoutes'
import admissionRoutes from './admissionRoutes'
import registrationRoutes from './registrationRoutes'
import baseRoutes from './baseRoutes'
import otherRoutes from './otherRoutes'

const routes = [
  baseRoutes,
  SubjectRoutes,
  reportRoutes,
  examinationRoutes,
  enquiryRoutes,
  transportRoutes,
  studentRoutes,
  staffRoutes,
  collectFeeRoutes,
  accountRoutes,
  attendanceRoutes,
  econtentRoutes,
  admissionRoutes,
  registrationRoutes,

  otherRoutes, // login, uiTesting, 404 etc.

]

export default routes
