export default {
  path: '/',
  component: () => import('@/layouts/LayoutBlank.vue'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/payment-registration',
      name: 'paymentRegistration',
      component: () => import('@/views/PaymentRegistration.vue'),
    },
    {
      path: 'online/enquiry',
      name: 'OnlineEnquiry',
      component: () => import('@/views/OnlineEnquiry.vue'),
    },
    {
      path: '/ui',
      name: 'uiTesting',
      component: () => import('@/views/UI.vue'),
    },
    {
      path: '/thankyou',
      name: 'thankyou',
      component: () => import('@/views/ThankYou.vue'),
    },
    {
      path: '/admission',
      name: 'admission',
      component: () => import('@/views/admission/Admission.vue'),
    },
    {
      path: '/payment/admission/thankyou',
      name: 'paymentAdmission',
      component: () => import('@/views/admission/ReceiptDownload.vue'),
    },
    {
      path: '/not-found',
      name: 'error-404',
      component: () => import('@/views/Error404.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/Error404.vue'),
    },

  ],
}
